
import Vue from 'vue';
import CalculatorScreen from '@/components/common/CalculatorScreen/CalculatorScreen.vue';
import CloudStep02Template from '@/templates/cloud/CloudStep02Template.vue';
import { createCloudServiceInfo } from '@/api/cloud/index';
import { CloudRegist } from '@/api/cloud/types';
import { PaymentType, GpayMethodType } from '@/types/common';
import { getCookie } from '@/utils/common.js';
import { HttpStatusCode } from '@/types/common';
import { AxiosError } from 'axios';

export default Vue.extend({
  name: 'CloudStep01Brain',
  components: { CalculatorScreen, CloudStep02Template },
  data() {
    return {
      paymentRegistCompleteFlag: false,
      paymentType: 'C' as PaymentType.CARD,
      autoSettle: 'Y',
      payMethod: '9',
      formState: {
        terms: {
          agree1: false,
        },
      },
    };
  },
  beforeCreate() {
    let scriptTag = document.createElement('script');
    let prefix = '';
    const APP_MODE = process.env.VUE_APP_MODE;
    const today = new Date();
    const billingScript = 'billing.gabia.com/js/popup/app2.js';
    const dateStr = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}-${today.getHours()}`;
    const scriptTags = Array.from(document.getElementsByTagName('script'));

    const isExistBillingScript = scriptTags.some((tag) => {
      return tag.src.includes(billingScript);
    });

    if (!isExistBillingScript) {
      if (APP_MODE === 'development') prefix = 'pt-';
      else if (APP_MODE === 'stage') prefix = 'qa-';
      scriptTag.src = `https://${prefix}${billingScript}?d=${dateStr}`;
      document.body.appendChild(scriptTag);
    }
  },
  mounted() {
    this.$GlobalLoading.hide();
    const gnbApp = document.querySelector('#gnb-app') as HTMLElement;
    gnbApp.style.zIndex = '900';
  },
  methods: {
    goodsRegistShow() {
      window.callback = this.cloudRegistAutoPayFnc;
      window.payment_pay_method = this.paymentType;
      const methodNewBtn = document.querySelector('div[name=method_new_btn]') as HTMLElement;
      methodNewBtn.click();
    },
    async cloudRegistAutoPayFnc(seq_id: string, gpay_method: GpayMethodType) {
      const param = {
        gasess: getCookie('gasession'),
        authsess: this.$store.state.cloudAuthSess,
        auto_settle: this.autoSettle,
        autopay_seqno: Number(seq_id), // 상결 모듈 내 값이 스트링과 인티저로 오는 경우 발생 임시 처리, 상결팀에 요청했음
        autopay_method: gpay_method,
      } as CloudRegist;

      this.payMethod = gpay_method as string;

      await this.createCloudServiceRegist(param);
    },
    async goNextStep() {
      if (!this.formState.terms.agree1) {
        alert('주의 사항 확인이 필요합니다.');
        return;
      }

      if (this.autoSettle === 'N') {
        //! 수동 신청
        const param = {
          gasess: getCookie('gasession'),
          authsess: this.$store.state.cloudAuthSess,
          auto_settle: this.autoSettle,
        } as CloudRegist;

        await this.createCloudServiceRegist(param);
      } else {
        this.goodsRegistShow();
      }
    },
    goPrevStep() {
      this.$router.push({
        name: 'apply-01',
        params: { service_type: 'cloud' },
      });
    },
    async createCloudServiceRegist(param: CloudRegist) {
      try {
        this.$GlobalLoading.show();
        const { status } = await createCloudServiceInfo(param);

        if (HttpStatusCode.OK === status) {
          alert('등록에 성공하였습니다.');
          this.$router.push({
            name: 'apply-03',
            params: { service_type: 'cloud' },
            query: { auto_settle: param.auto_settle, pay_method: this.payMethod },
          });
        }
      } catch (error) {
        const errorResponse = (error as AxiosError)?.response?.data || {
          description: '인증 진행중 에러가 발생했습니다.',
        };
        alert(errorResponse.description);
      } finally {
        this.$GlobalLoading.hide();
      }
    },
    changeAutoSettleFlag(flag: 'Y' | 'N') {
      this.autoSettle = flag;
    },
  },
});
