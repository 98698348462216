const PageLeaveMixin = {
  beforeRouteLeave(to, from, next) {
    const { confirm, message } = this.$route.meta;

    if (!confirm || window.confirm(message)) {
      next();
    }
  },

  mounted() {
    window.addEventListener('beforeunload', this.unloadEvent);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.unloadEvent);
  },

  methods: {
    unloadEvent(event) {
      event.preventDefault();
      event.returnValue = '';
    },
  },
};

export default PageLeaveMixin;
