
import Vue from 'vue';
import BillLayout from '@/layouts/BillLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import PageLeaveMixin from '@/mixins/PageLeaveMixin.js';
import { getDateStringFromFormat } from '@/utils/common.js';
import { UserInfo } from '@/api/gabia-user/types';

export default Vue.extend({
  name: 'CloudStep02Template',
  components: { BillLayout, TableLayout, ColumnLayout, FormLayout },
  mixins: [PageLeaveMixin],
  model: { prop: 'formState', event: 'change' },
  props: {
    formState: {
      type: Object,
      default() {
        return {
          terms: {
            agree1: false,
          },
        };
      },
    },
  },
  data() {
    return {
      formTitle,
      isAutoSettle: true,
      agreeAll: false,
    };
  },
  computed: {
    getCurrnetDate(): string {
      return getDateStringFromFormat('yyyy-mm-dd');
    },
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  watch: {
    agreeBoth(newValue) {
      this.agreeAll = newValue;
    },
  },
  mounted() {
    if (this.$route.meta !== undefined) {
      this.$route.meta.confirm = true;
      this.$route.meta.message = '현 진행상태가 유지되지 않습니다. 계속 진행하시겠습니까?';
    }
  },
  methods: {
    onChangeTerms(e) {
      const { name, checked } = e;

      this.$emit('change', {
        ...this.formState,
        terms: {
          ...this.formState.terms,
          [`agree${name}`]: checked,
        },
      });
    },
    onChanegePaymentAuto(isAuto) {
      this.isAutoSettle = isAuto.detail === 'Y';
      this.$emit('auto-settle-flag-change', isAuto.detail);
    },
  },
});
